import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope, faMapMarker } from '@fortawesome/free-solid-svg-icons'

class ContactPageComponent extends React.Component {
  validationRules = {
    name: { rule: /^[a-zA-Z- ]+$/, error: 'The name entered is not valid.'},
    email: { rule: /[^@]+@[^\.]+\..+/, error: 'The email address entered is not valid.'},
    phone: { rule: /^[0-9+]{11}$/, error: 'The phone number entered is not valid.'},
    message: { rule: /^[a-zA-Z0-9 ]+$/, error: 'Please enter a message'},
  }

  state = {
    name: { value: '', isValid: false },
    email: { value: '', isValid: false },
    phone: { value: '', isValid: false },
    message: { value: '', isValid: false },
    consented: false,
    formValid: false,
    showConsentedMessage: false,
    showSuccessMessage: false,
    showErrorMessage: false
  }

  handleInputChange = (event) => {
    const target = event.target;
    const val = target.value;
    const name = target.name;
    this.setState({
      [name]: { value: val },
    }, () => this.validateField(name, val));
  }

  updateConsented = (event) => {
    const target = event.target;
    const checked = target.checked;
    this.setState({
      consented: checked
    }, this.validateForm);
  }

  sendEmail = (event) => {
    event.preventDefault();

    const { email, sesTemplate, emailEndpoint } = this.props.data.site.siteMetadata;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', emailEndpoint, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = () => {
      this.resetState();
      this.setState({
        showSuccessMessage: true,
        showErrorMessage: false
      });
    };
    xhr.onerror = (err) => {
      this.setState({
        showSuccessMessage: false,
        showErrorMessage: true
      });
    };
    xhr.send(JSON.stringify({
      toEmail: email,
      templateId: sesTemplate,
      templateParams: {
        name: this.state.name.value,
        email: this.state.email.value,
        phone: this.state.phone.value,
        message: this.state.message.value,
        consented: this.state.consented.value
      }
    }));
  }

  validateField = (name, val) => {
    if (!!this.validationRules[name]) {
      const { rule } = this.validationRules[name];
      const isValid = rule.test(val);
      this.setState({
        [name]: { value: val, isValid: isValid },
      }, this.validateForm);
    } else {
      this.validateForm();
    }
  }

  validateForm = () => {
    console.log(this.state);
    this.setState({formValid: !!this.state.consented && this.state.name.isValid && this.state.email.isValid && this.state.phone.isValid && this.state.message.isValid});
  }

  resetState = () => {
    this.setState({
      name: { value: '', isValid: false },
      email: { value: '', isValid: false },
      phone: { value: '', isValid: false },
      message: { value: '', isValid: false },
      showConsentedMessage: false,
      consented: false,
      formValid: false
    });
  }

  render() {
    return (
      <Layout>
        <SEO title={this.props.data.content.frontmatter.title} />
        <Container>
          <Row className="pt-5 pb-3">
            <Col sm={12} md={6} className="contact-details">
              <h1 className="display-4 mb-3">{this.props.data.content.frontmatter.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: this.props.data.content.html }} />
              <div>
                <FontAwesomeIcon icon={faPhone} />
                <a className="ml-2" href={`tel:` + this.props.data.site.siteMetadata.contactNumber}>
                {this.props.data.site.siteMetadata.contactNumber}
                </a>
              </div>
              <div>
                <FontAwesomeIcon icon={faEnvelope} />
                <a className="ml-2" href={`mailto:` + this.props.data.site.siteMetadata.email}>
                  {this.props.data.site.siteMetadata.email}
                </a>
              </div>
              <div className="hidden-sm-down"><FontAwesomeIcon icon={faMapMarker} />
                <span className="ml-2">{this.props.data.site.siteMetadata.address}</span>
              </div>
            </Col>
            <Col sm={12} md={6} className="pt-5">
              {this.state.showSuccessMessage && <Alert variant="success">Your message has been sent successfully</Alert>}
              {this.state.showErrorMessage && <Alert variant="danger">Your message could not be sent. Please try again later</Alert>}
              <Form onSubmit={this.sendEmail}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control 
                    name="name"
                    type="text"
                    placeholder="Enter name"
                    value={this.state.name.value}
                    onChange={this.handleInputChange}
                    isInvalid={!!this.state.name.value && !this.state.name.isValid}
                  />
                {!!this.state.name.value &&!this.state.name.isValid && <Form.Control.Feedback type="invalid">{this.validationRules.name.error}</Form.Control.Feedback> }
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Enter email address"
                    value={this.state.email.value}
                    onChange={this.handleInputChange} 
                    isInvalid={!!this.state.email.value && !this.state.email.isValid}
                  />
                  {!!this.state.email.value && !this.state.email.isValid && <Form.Control.Feedback type="invalid">{this.validationRules.email.error}</Form.Control.Feedback> }
                </Form.Group>
                <Form.Group>
                  <Form.Label>Contact number</Form.Label>
                  <Form.Control
                    name="phone"
                    type="tel" 
                    placeholder="Enter contact number"
                    value={this.state.phone.value}
                    onChange={this.handleInputChange}
                    isInvalid={!!this.state.phone.value && !this.state.phone.isValid}
                  />
                  {!!this.state.phone.value && !this.state.phone.isValid && <Form.Control.Feedback type="invalid">{this.validationRules.phone.error}</Form.Control.Feedback> }
                </Form.Group>
                <Form.Group>
                  <Form.Label>Message</Form.Label>
                  <Form.Control 
                    name="message"
                    as="textarea" 
                    placeholder="Enter message"
                    value={this.state.message.value}
                    onChange={this.handleInputChange}
                    isInvalid={!!this.state.message.value && !this.state.message.isValid}
                  />
                  {!!this.state.message.value && !this.state.message.isValid && <Form.Control.Feedback type="invalid">{this.validationRules.message.error}</Form.Control.Feedback> }
                </Form.Group>
                <Form.Check 
                  name="consented"
                  className="mb-2"
                  checked={this.state.consented}
                  onChange={this.updateConsented}
                  label="I consent to having this website store my submitted information for the sole purpose of responding to my inquiry." 
                />
                <Button variant="primary" type="submit" disabled={!this.state.formValid}  >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const data = graphql`
  query {
    content: markdownRemark(frontmatter: { path: { eq: "/contact" } }) {
      ...StandardMarkdownFragment
    }
    site: site {
      siteMetadata {
        email
        address
        contactNumber
        sesTemplate
        emailEndpoint
      }
    }
  }
`;

const ContactPage = ({data}) => {

  return (
    <ContactPageComponent data={data} />
  );
}

export default ContactPage